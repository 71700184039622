import {
  all,
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  delay
} from 'redux-saga/effects';
import { browserHistory } from 'react-router';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import pickBy from 'lodash/pickBy';
import zip from 'lodash/fp/zip';

import CoreTeamsApi, { TeamMembersApi } from 'common/core/teams';

import { FeatureFlags } from 'common/feature_flags';
import * as TeamsCatalogApi from 'common/teams-api';
import UsersApi from 'common/users-api';

import * as Actions from './actions';
import * as Selectors from '../selectors.js';
import * as GlobalActions from '../actions.js';
import * as TeamSelectors from './reducers/teamsReducer.js';

import { RIGHTS } from 'common/teams/constants';
import { TeamRole } from '@socrata/core-teams-api';

const UX_DELAY = 250;

export function* addTeamMembers({ payload: { teamId } }: { payload: { teamId: string } }) {
  yield put(Actions.showAddTeamMembersModal());

  let hasErrors;
  do {
    hasErrors = false;
    yield put(Actions.enableAddTeamMembersModal());
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const userSearch = yield fork(watchUserSearchQueryChanged);
    const { submit, cancelModal } = yield race({
      submit: take(Actions.SUBMIT_ADD_TEAM_MEMBERS_MODAL),
      cancelModal: take(Actions.CANCEL_ADD_TEAM_MEMBERS_MODAL)
    });
    yield cancel(userSearch);
    if (submit) {
      yield put(Actions.disableAddTeamMembersModal());
      yield delay(UX_DELAY);
      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
      const roleId = yield select(Selectors.getDefaultMemberRoleId);
      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
      const selectedUsers = yield select(Selectors.getTeamAddMemberSelectedUsers);
      const selectedUserIds = map(TeamSelectors.getUserId, selectedUsers);

      // @ts-expect-error TS(2339) FIXME: Property 'map' does not exist on type 'LodashMap1x... Remove this comment to see the full error message
      const calls = selectedUserIds.map((userId: string) => call(fetchAddTeamMember, teamId, userId, roleId));
      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
      const results = yield all(calls);

      // @ts-expect-error TS(2339) FIXME: Property 'map' does not exist on type 'LodashZip1x... Remove this comment to see the full error message
      const resultActions = zip(selectedUsers, results).map(([user, result]: [string, any]) => {
        if (result instanceof Error) {
          hasErrors = true;
          console.error(result);
          return put(
            Actions.addTeamMemberFailure({
              translationKey: 'users.edit_team.add_team_member_failure_html',
              displayName: TeamSelectors.getUserScreenName(user)
            })
          );
        } else {
          return put(Actions.addTeamMemberSuccess(teamId, TeamSelectors.mapResultToMember(user, roleId)));
        }
      });
      yield all(resultActions);
      if (!hasErrors) {
        yield put(GlobalActions.showLocalizedSuccessNotification('users.edit_team.add_team_members_success'));
      }
    }
    if (cancelModal) {
      /** NOTE:
       * Due to the way this is currently set up, the parent function will resolve the same way
       * regardless of whether cancelModal is truthy or not since hasErrors will never truthy
       * unless submit is truthy. I can think of only one reason that both submit and cancelModal
       * would both be truthy with how the conditions are currently written, which is to attempt
       * to submit once and then cancel the loop regardless of whether errors occurred.
       *
       * Barring this strange scenario, if submit is truthy then this block will be skipped.
       *
       * My belief is that this IF block was added as a placeholder where we could perform additional
       * actions as part of cancelling the modal which might result in hasErrors being set to truthy
       * outside of the submit IF block above. I am leaving it in place as a placeholder.
       *
       * It is worth noting and understanding that this means we cannot test the difference between
       * cancelModal being truthy or falsey when submit is falsey. I have updated the description of
       * the test addressing this block to more accurately reflect this nuance.
       */
      break;
    }
  } while (hasErrors);
  yield put(Actions.hideAddTeamMembersModal());
}

export function* changeMemberRole({
  payload: { teamId, userId, roleId }
}: {
  payload: { teamId: string; userId: string; roleId: TeamRole };
}) {
  try {
    yield call([TeamMembersApi, TeamMembersApi.updateTeamMember], { teamId, userId, role: roleId });
    yield put(Actions.changeMemberRoleSuccess(teamId, userId, roleId));
  } catch (error) {
    console.error(error);
  }
}

export function* deleteTeam({ payload: { id } }: { payload: { id: string } }) {
  try {
    yield call([CoreTeamsApi, CoreTeamsApi.deleteTeam], { teamId: id });
    yield put(Actions.deleteTeamSuccess(id));
    yield put(GlobalActions.showLocalizedSuccessNotification('users.notifications.delete_team_success'));
  } catch (error) {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const errorJson = yield call(error.json);
    yield put(Actions.deleteTeamFailure(errorJson));
    yield put(GlobalActions.showLocalizedErrorNotification('users.errors.server_error_html'));
  }
}

export function* loadTeams() {
  try {
    const { teams, resultCount } = yield call(fetchTeams);
    yield put(Actions.loadTeamsSuccess(teams, resultCount));
  } catch (error) {
    yield put(Actions.loadTeamsFailure(error));
  }
}

export function* loadTeamRoles() {
  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const teamRoles = yield call([TeamMembersApi, TeamMembersApi.getRoles]);
    yield put(Actions.loadTeamRolesSuccess(teamRoles));
  } catch (error) {
    yield put(Actions.loadTeamRolesFailure(error));
  }
}

export function* removeTeamMember({
  payload: { teamId, userId }
}: {
  payload: { teamId: string; userId: string };
}) {
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const teamMember = yield select(Selectors.findTeamMemberById, teamId, userId);
  const displayName = TeamSelectors.getUserDisplayName(teamMember);
  try {
    try {
      yield call([TeamMembersApi, TeamMembersApi.removeTeamMember], { teamId, userId });
    } catch (error) {
      if (!(error instanceof SyntaxError)) {
        // No JSON response is returned, which causes an error
        throw error;
      }
    }
    yield put(Actions.removeTeamMemberSuccess(teamId, userId));
    yield put(
      GlobalActions.showLocalizedSuccessNotification('users.edit_team.remove_team_member_success', {
        displayName
      })
    );
  } catch (error) {
    console.warn(error);
    yield put(
      GlobalActions.showLocalizedErrorNotification('users.edit_team.remove_team_member_failure', {
        displayName
      })
    );
  }
}

export function* teamViewNavigation(action: { payload: { id: string } }) {
  const {
    payload: { id }
  } = action;
  try {
    yield call(loadTeam, action);
  } catch (error) {
    console.warn(`Unable to navigate to team with id ${id}`);
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const path = yield select(Selectors.getTeamsAdminPath);
    yield call(browserHistory.push, path);
  }
}

/**
 * Calls our to the teams API to add a team member
 * Catches errors and returns them, instead of allowing them to surface, to allow for fanning out requests with
 * `all` and not rejecting the batch for one failure
 * @param teamId
 * @param userId
 * @param roleId
 * @returns result or error
 */
export function* fetchAddTeamMember(teamId: string, userId: string, role: TeamRole) {
  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    return yield call([TeamMembersApi, TeamMembersApi.addTeamMember], { teamId, userId, role });
  } catch (error) {
    return error;
  }
}

export function* userSearchQueryChanged({
  payload: { currentQuery, filters }
}: {
  payload: { currentQuery: string; filters: object };
}) {
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const domain = yield select(Selectors.getDomain);

  if (!isEmpty(currentQuery)) {
    try {
      const rights = [];
      if (FeatureFlags.value('strict_permissions')) {
        rights.push(RIGHTS.CAN_COLLABORATE);
      }
      filters = {
        ...filters,
        ...pickBy({ rights })
      };
      const { results } = yield call(UsersApi.autocomplete, domain, currentQuery, filters);
      yield put(Actions.userSearchResults(results));
    } catch (error) {
      console.warn('Error searching for team members', error);
    }
  }
}

export function* watchUserSearchQueryChanged() {
  yield takeLatest<{
    type: string;
    payload: {
      currentQuery: string;
      filters: object;
    };
  }>(Actions.USER_SEARCH_QUERY_CHANGED, userSearchQueryChanged);
}

export function* loadTeam({ payload: { id } }: { payload: { id: string } }) {
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const team = yield call([CoreTeamsApi, CoreTeamsApi.getTeam], { teamId: id });
  yield put(Actions.loadTeamSuccess(team));
}

export function* gotoPage() {
  yield put(Actions.teamsSearch());
}

export function* sortTeamColumn() {
  yield put(Actions.teamsSearch());
}

export const selectOptions = (state: any) => ({
  offset: Selectors.getTeamsOffset(state),
  query: Selectors.getTeamsSearchQuery(state),
  limit: Selectors.getTeamsResultsLimit(state),
  orderBy: Selectors.getTeamsOrderBy(state),
  sortDirection: Selectors.getTeamsSortDirection(state)
});

export function* fetchTeams() {
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const options = yield select(selectOptions);
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const domain = yield select(Selectors.getDomain);
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  return yield call(TeamsCatalogApi.getTeams, domain, options);
}

export function* teamsAutocomplete({
  payload: { query, callback }
}: {
  payload: { query: string; callback: (searchResults: any) => void };
}) {
  if (query !== '') {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const domain = yield select(Selectors.getDomain);
    try {
      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
      const searchResults = yield call(TeamsCatalogApi.autocomplete, domain, query);
      yield call(callback, searchResults);
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  }
}

export function* teamsSearch() {
  try {
    const { teams, resultCount } = yield call(fetchTeams);
    yield put(Actions.teamsSearchSuccess(teams, resultCount));
  } catch (error) {
    console.warn('Unable to search for teams, using empty list.', error);
    yield put(Actions.teamsSearchFailure(error));
  }
}

export default [
  takeEvery<{ type: string; payload: { teamId: string } }>(Actions.ADD_TEAM_MEMBERS, addTeamMembers),
  takeEvery<{
    type: string;
    payload: {
      teamId: string;
      userId: string;
      roleId: TeamRole;
    };
  }>(Actions.CHANGE_MEMBER_ROLE, changeMemberRole),
  takeEvery<{ type: string; payload: { id: string } }>(Actions.DELETE_TEAM, deleteTeam),
  takeEvery(Actions.GOTO_TEAM_PAGE, gotoPage),
  takeEvery<{
    type: string;
    payload: {
      id: any;
    };
  }>(Actions.LOAD_TEAM, loadTeam),
  takeEvery(Actions.LOAD_TEAM_ROLES, loadTeamRoles),
  takeEvery(Actions.LOAD_TEAMS, loadTeams),
  takeEvery<{
    type: string;
    payload: {
      teamId: string;
      userId: string;
    };
  }>(Actions.REMOVE_TEAM_MEMBER, removeTeamMember),
  takeEvery(Actions.SORT_TEAM_COLUMN, sortTeamColumn),
  takeLatest(Actions.TEAMS_SEARCH, teamsSearch),
  // eslint-disable-next-line no-spaced-func
  takeLatest<{
    type: string;
    payload: {
      query: string;
      callback: (searchResults: any) => void;
    };
  }>(Actions.TEAMS_AUTOCOMPLETE, teamsAutocomplete),
  takeLatest<{
    type: string;
    payload: {
      id: string;
    };
  }>(Actions.TEAM_VIEW_NAVIGATION, teamViewNavigation)
];
