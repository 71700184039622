import { call, put, takeEvery } from 'redux-saga/effects';
import type { Role, AssignRoleToUserRequest, RemoveRoleFromUserRequest } from '@socrata/core-roles-api';
import CoreRolesApi from 'common/core/roles';
import { browserHistory } from 'react-router';
import * as Actions from './actions.js';
import * as GlobalActions from '../actions.js';

import get from 'lodash/fp/get';

function* determineErrorMessage(error: any) {
  if (error.status == null) {
    return 'users.errors.null_error_code';
  } else if (error.status === 400) {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const data = yield error.json();
    if (data.code === 'ROLE.CHANGE_OWN_ROLE') {
      return 'users.errors.own_role';
    }
  }
  return 'users.errors.unknown';
}

const sortRoles = (a: Role, b: Role): number => {
  if (a.isDefault === b.isDefault) {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  } else {
    return a.isDefault ? -1 : 1;
  }
};

export function* changeUserRole({
  payload: { userId, newRole }
}: {
  payload: { userId: string; newRole: number };
}) {
  try {
    const request: AssignRoleToUserRequest = {
      userId,
      roleId: newRole
    };
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call([CoreRolesApi, CoreRolesApi.assignRoleToUser], request);
    const newRoleName = get('newRole.name', response);
    yield put(Actions.changeUserRoleSuccess(userId, newRole, newRoleName));
    yield put(GlobalActions.showLocalizedSuccessNotification('users.notifications.role_changed'));
  } catch (error) {
    console.warn(`Unable to change user role for user ID ${userId} and role ${newRole}`, error);
    yield put(Actions.changeUserRoleFailure(userId, error));
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const errorMessage = yield determineErrorMessage(error);
    yield put(GlobalActions.showLocalizedErrorNotification(errorMessage));
  }
}

export function* changeUserRoleFilter({ payload: { roleId } }: { payload: { roleId: number | string } }) {
  yield put(GlobalActions.gotoUserPage(1));
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const currentLocation = yield call(browserHistory.getCurrentLocation);
  const location = Object.assign({}, currentLocation);
  const query = roleId === 'all' ? { roleId: undefined } : { roleId };
  Object.assign(location.query, query);
  yield call(browserHistory.push, location);
}

export function* loadRoles() {
  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const roleData = yield call([CoreRolesApi, CoreRolesApi.getAllRoles]);
    const roles = roleData.sort(sortRoles);
    yield put(Actions.loadRolesSuccess(roles));
  } catch (error) {
    console.warn('Unable to load roles, using empty list.', error);
    yield put(Actions.loadRolesFailure(error));
  }
}

export function* removeUserRole({
  payload: { userId, roleId }
}: {
  payload: { userId: string; roleId: number };
}) {
  try {
    const request: RemoveRoleFromUserRequest = {
      userId,
      roleId
    };
    yield call([CoreRolesApi, CoreRolesApi.removeRoleFromUser], request);
    yield put(Actions.removeUserRoleSuccess(userId, roleId));
    yield put(GlobalActions.showLocalizedSuccessNotification('users.notifications.role_removed'));
  } catch (error) {
    console.warn(`Unable to remove user role for user ID ${userId} and role ${roleId}`, error);
    yield put(Actions.removeUserRoleFailure(userId, error));
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const errorMessage = yield determineErrorMessage(error);
    yield put(GlobalActions.showLocalizedErrorNotification(errorMessage));
  }
}

export default [
  takeEvery<{ type: string; payload: { userId: string; newRole: number } }>(
    Actions.CHANGE_USER_ROLE,
    changeUserRole
  ),
  takeEvery<{ type: string; payload: { roleId: string | number } }>(
    Actions.CHANGE_USER_ROLE_FILTER,
    changeUserRoleFilter
  ),
  takeEvery(Actions.LOAD_ROLES, loadRoles),
  takeEvery<{ type: string; payload: { userId: string; roleId: number } }>(
    Actions.REMOVE_USER_ROLE,
    removeUserRole
  )
];
